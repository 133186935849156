<template>
  <div id="app" class="container">
    <div class="row footer">
      <div class="col p-3">
        <p>Celebrating the Marriage of</p>
        <p class="p2">Katherine Michele Tolbert</p>
        <p>to</p>
        <p class="p2">Jeffrey Michael Levy</p>
        <p>Nov. 14, 2020</p>
      </div>
    </div>
    <div class="row rings">
      <div class="col">
        <img src="./assets/avatars/avatar-4.png" alt="" />
      </div>
      <div class="col pt-4">
        <img alt="" class="rings shadow " src="./assets/rings.jpg" />
      </div>
      <div class="col">
        <img src="./assets/avatars/avatar-5.png" alt="" />
      </div>
    </div>

    <div class="row thumbs" v-for="(img, idx) in images" :key="idx">

      <div class="col">
        <a
          :href="'https://lalafafa4ever.s3-us-west-2.amazonaws.com/' + img"
          target="_blank"
        >
          <img class="shadow-sm" :src="'./img/thumbs/' + img" />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "App",
  components: {},
  data() {
    return {
      images: [
        "DSC_0053.JPG",
        "DSC_0203.JPG",
        "DSC_0140.JPG",
        "DSC_0087.JPG",
        "DSC_0174.JPG",
        "DSC_0183.JPG",
        "DSC_0027.JPG",
        "DSC_0190.JPG",
        "DSC_0106.JPG",
        "DSC_0059.JPG",
        "DSC_0044.JPG",
        "DSC_0051.JPG",
        "DSC_0042.JPG",
        "DSC_0202.JPG",
        "DSC_0162.JPG",
        "DSC_0113.JPG",
        "DSC_0115.JPG",
        "DSC_0125.JPG",
        "DSC_0112.JPG",
        "DSC_0138.JPG",
        "DSC_0126.JPG",
        "DSC_0120.JPG",
        "DSC_0122.JPG",
        "DSC_0074.JPG",
        "DSC_0101.JPG",
        "DSC_0029.JPG",
        "DSC_0062.JPG",
        "DSC_0038.JPG",
        "DSC_0052.JPG",
        "DSC_0046.JPG",
        "DSC_0201.JPG",
        "DSC_0171.JPG",
        "DSC_0025.JPG",
        "DSC_0141.JPG",
        "DSC_0022.JPG",
        "DSC_0030.JPG",
        "DSC_0071.JPG",
        "DSC_0043.JPG",
        "DSC_0060.JPG",
        "DSC_0021.JPG",
        "IMG_1142.JPG",
        "IMG_1143.JPG",
        "IMG_1148.JPG",
        "IMG_1149.JPG",
        "IMG_1150.JPG",
        "IMG_3693.JPG",
        "IMG_3694.JPG",

      ],
    };
  },
};
</script>

<style>
/* Color Theme Swatches in Hex */
.Paleta-rosa-1-hex {
  color: #DA7585;
}
.Paleta-rosa-2-hex {
  color: #FA90A4;
}
.Paleta-rosa-3-hex {
  color: #F6AFAD;
}
.Paleta-rosa-4-hex {
  color: #F2BBC1;
}
.Paleta-rosa-5-hex {
  color: #FBEAE2;
}
div.thumbs {
  padding: 1rem;
}
html body {
  background-color: #FBEAE2;
}

@font-face {
  font-family: "Parisienne";
  src: local("Parisienne"),
    url(./assets/fonts/Parisienne-Regular.ttf)format("truetype");
}
div.heading {
  font-family: "Parisienne";
  font-size: 48pt;
}
p.p2 {
  font-size: 22pt;
  font-weight: bold;
  color: #DA7585;
}
div.footer {
  font-family: "Parisienne";
  font-size: 18pt;
  line-height: 90%;
}

img.rings {
  max-width: 325px;
}
div.rings {
  background-color: #FA90A4;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
